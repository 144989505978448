import React, {useEffect} from "react";
import {Add, Remove} from '@material-ui/icons';
import {
    Grid,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    makeStyles,
    Typography, Divider, Button
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    catalogueItemContainer: {
        height: 500,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        cursor: 'pointer',
        transition: 'all 0.1s ease',

        '&:hover, &:focus':{
            transform: 'scale(1.01)',
        }
    },
    item: {
        paddingLeft: 40,
    },
    subItem: {
        paddingLeft: 60,
    },
    media: {
        height: 150,
        backgroundSize: 'contain',
        marginTop: 50
    },
    counterButton: {
        height: 50,
        width: 50,
        marginLeft: 10,
    },
    cardRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        height: 60
    },
    counterInput: {
        width: 70,
    },
    row: {
        display: 'flex',

    }
}));

function CatalogueItem(props) {

    const classes = useStyles();

    return (
        <Grid item md={3} xs={12} onClick={()=>props.handleClick(props.item.id)}>
            <Card className={classes.catalogueItemContainer}>
                <CardMedia
                    className={classes.media}
                    image={props.item.images[0]}
                    title={props.item.name}
                />
                <CardContent style={{height: 300}}>
                    <Typography variant="h5">
                        {props.item.name}
                    </Typography>
                    <Divider/>
                    {props.item.description.split(';').map(x=>
                        <Typography variant="body2" color="textSecondary" style={{marginTop: 10}}>
                            {x}
                        </Typography>
                    )}

                    <Divider style={{marginTop: 20}}/>
                    {/*<Typography variant="h6">*/}
                    {/*    {props.item.price} KM*/}
                    {/*</Typography>*/}
                </CardContent>
                <CardActions>
                    <Button color="primary" variant="contained" style={{width: '100%', fontSize: 14}} onClick={(e) => {e.stopPropagation(); props.handleAddToShoppingCart(props.item.id);}}>
                        Dodaj u korpu
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
}

export default CatalogueItem;