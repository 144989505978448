import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Icon, Paper, Typography} from '@material-ui/core';
import {Call, MailOutline, Room, Schedule} from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    footerLayout: {
        width: '100%',
        backgroundColor: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    footerRow: {
        width: '100%',
        height: 800,
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: 60,
        marginBottom: 30
    },
    copyright: {
        backgroundColor: 'black',
        width: '100%',
        color: '#fafafa',
        display: 'flex',
        justifyContent: 'center',
        marginTop:100
    },
    contactContainer: {
        width: 450,
        height: 750,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 4px 8px gray',
        borderRadius: '4px',
        paddingLeft: 30,
        paddingRight: 30
    },
    row: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: 30
    },
    rowStart: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 30
    },
}));

function Footer() {
    const classes = useStyles();
    return (
        <Grid item xs={12} className={classes.footerLayout}>
            <Grid container>
                <Grid item md={4}>

                </Grid>
                <Grid item md={4} xs={12}>
                    <Paper style={{maxWidth:'500px',padding:60, marginTop:80}}>
                        <div className={classes.row}>
                            <Typography variant='h5'>
                                Kontakt informacije
                            </Typography>
                        </div>
                        <div className={classes.rowStart}>
                            <Call/>
                            <Typography>
                                +38761504743
                            </Typography>
                        </div>
                        <div className={classes.rowStart}>
                            <Call/>
                            <Typography>
                                +38761749993
                            </Typography>
                        </div>
                        <div className={classes.rowStart}>
                            <MailOutline/>
                            <Typography>
                                officestoresarajevo@gmail.com
                            </Typography>
                        </div>
                        <div className={classes.rowStart}>
                            <Schedule/>
                            <Typography>
                                PON-PET 8:00h-16:300h
                            </Typography>
                        </div>
                        <div className={classes.rowStart}>
                            <Room/>
                            <Typography>
                                Ante Babića 13
                            </Typography>
                        </div>
                        <div className={classes.rowStart}>
                                <iframe width="100%" height="300" style={{border: 0}} loading="lazy" allowFullScreen
                                        src="https://www.google.com/maps/embed/v1/place?q=43.843244%2C%2018.341737&key=AIzaSyBA0mwaEYMakDZ-VU9CoIZ-lq8lWP_W_8Y"></iframe>

                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <div className={classes.copyright}>
                Benjamin Šehić i Fatima Muhović © 2021. Sva prava zadržana. tel. +387 62 867-222; bsehic1@outlook.com
            </div>

        </Grid>
    );
}

export default Footer;