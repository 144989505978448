import React, {useState} from "react";
import {IconButton, makeStyles} from "@material-ui/core";
import {Clear, NavigateBefore, NavigateNext, Remove} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    outerContainer: {
        height: '50vh',
        position: 'relative',
        backgroundRepeat:"no-repeat",
        backgroundPosition:"center",
        backgroundSize:"contain",
        marginTop:30
    },
    previousButton: {
        position: 'relative',
        top: '45%',
        left: '0%',
        color: theme.palette.primary.main,
    },
    nextButton: {
        position: 'absolute',
        top: '45%',
        right: '0%',
        color: theme.palette.primary.main
    },
    closeButton: {
        position: 'absolute',
        top: '-8%',
        right: '0%',
        color: theme.palette.primary.main
    }
}));

function Carousel(props) {
    const classes = useStyles();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleNext = () => {
        if (currentImageIndex === props.images.length - 1) {
            setCurrentImageIndex(0);
        } else {
            setCurrentImageIndex(currentImageIndex + 1);
        }
    }
    const handlePrevious = () => {
        if (currentImageIndex === 0) {
            setCurrentImageIndex(props.images.length - 1);
        } else {
            setCurrentImageIndex(currentImageIndex - 1);
        }
    }

    return (
        <div className={classes.outerContainer} style={{backgroundImage: `url(${props.images[currentImageIndex]})`}}>
            <IconButton
                className={classes.closeButton}
                onClick={props.handleClose}
            >
                <Clear style={{fontSize:30}}/>
            </IconButton>
            <IconButton
                className={classes.previousButton}
                onClick={handlePrevious}
            >
                <NavigateBefore style={{fontSize:30}}/>
            </IconButton>
            <IconButton
                className={classes.nextButton}
                onClick={handleNext}
            >
                <NavigateNext style={{fontSize:30}}/>
            </IconButton>
        </div>
    );
}

export default Carousel;