import React, {useEffect, useState} from "react";
import {ExpandMore, Search} from '@material-ui/icons';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Paper,
    TextField,
    Typography
} from "@material-ui/core"

import CatalogueItem from "./CatalogueItem";
import ShoppingCart from "./ShoppingCart";
import CatalogueItemDetails from "./CatalogueItemDetails";
import {Pagination} from "@material-ui/lab";

const pageSize = 50;
const useStyles = makeStyles((theme) => ({
    layoutContainer: {
        width: '100%',
        padding: 20
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary
    },
    searchButton: {
        width: 300,
        height: 55,
        marginTop: 8,
        textTransform: 'capitalize'
    },
    all: {
        paddingLeft: 20
    },
    item: {
        paddingLeft: 40,
    },
    subItem: {
        paddingLeft: 60,
    },
    media: {
        height: 200,
        backgroundSize: 'contain',
        marginTop: 50
    },
    counterButton: {
        height: '100%',
        marginRight: 5
    },
    cardRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        height: 60
    },
    counterInput: {
        height: 20,
        width: 80
    },
    shoppingCart: {
        padding: 10
    },
    row: {
        display: 'flex',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 100
    },
}));

function Catalogue() {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([1]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState(items);
    const [itemsToDisplay, setItemsToDisplay] = useState(items);
    const [itemWithDetails, setItemWithDetails] = useState(null);
    const [total, setTotal] = useState(0);
    const [query, setQuery] = useState("");
    const [flatCategories, setFlatCategories] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [isMobileLayout,setIsMobileLayout] = useState(false);
    const [numberOfPages,setNumberOfPages] = useState(0);
    const [selectedPage,setSelectedPage] = useState(1);

    const handleFetchItems = () => {
        const itemsUrl = "https://office-store-backend.herokuapp.com/items";
        fetch(itemsUrl)
            .then(res => res.json())
            .then(data => {
                setItems(data);
                const num = data.length/pageSize;
                if(num % parseInt(num) === 0){
                    setNumberOfPages(parseInt(num));
                } else {
                    setNumberOfPages(parseInt(num)+1);
                }
                setShowLoader(false);
            })
            .catch(err => console.log(err))

        const categoriesUrl = "https://office-store-backend.herokuapp.com/categories";
        fetch(categoriesUrl)
            .then(res => res.json())
            .then(json => {
                let newCategories = [];
                json.forEach(item => {
                    let formattedCategory = {...item};
                    if (formattedCategory.parent === 0) {
                        formattedCategory.type = "all";
                        newCategories.push(formattedCategory);
                    } else if (formattedCategory.parent === 1) {
                        formattedCategory.type = "item";
                        formattedCategory.children = [];
                        const indexOfCategory = newCategories.findIndex(category => category.id === formattedCategory.id);
                        if (indexOfCategory !== -1) {
                            newCategories[indexOfCategory].name = formattedCategory.name;
                        } else {
                            newCategories.push(formattedCategory);
                        }
                    } else {
                        formattedCategory.type = "sub-item";
                        const indexOfParent = newCategories.findIndex(category => category.id === formattedCategory.parent);
                        if (indexOfParent === -1) {
                            const parent = {
                                id: formattedCategory.id,
                                type: "item",
                                children: [formattedCategory.id]
                            }
                            newCategories.push(parent);
                        } else {
                            newCategories[indexOfParent].children.push(formattedCategory);
                        }
                    }
                });
                setCategories(newCategories);
            })
            .catch(err => console.log(err))

    }

    useEffect(() => {
        handleFetchItems();
        if(window.innerWidth < 768){
            setIsMobileLayout(true);
        }
    }, [])

    useEffect(() => {
        setItemsToDisplay(items);
        setFilteredItems(items);
    }, [items])

    useEffect(() => {
        calculateTotal();
    }, [selectedItems])

    useEffect(() => {
        handleFlattenCategories();
    }, [categories]);

    const handleFlattenCategories = () => {
        const newFlatCategories = [];
        categories.forEach(category => {
            newFlatCategories.push({
                id: category.id,
                name: category.name,
                type: category.type
            });
            if (category.children) {
                category.children.forEach(child => {
                    newFlatCategories.push({
                        id: child.id,
                        name: child.name,
                        type: child.type
                    });
                });
            }
        });
        setFlatCategories(newFlatCategories);
    };

    const calculateTotal = () => {
        let sum = 0;
        selectedItems.forEach(item => {
            sum += item.price * item.count;
        });
        setTotal(sum);
    }

    const handleCheckboxToggle = (categoryId) => () => {

        const indexOfCategory = selectedCategories.indexOf(categoryId);
        const newSelectedCategories = [...selectedCategories];

        if (indexOfCategory === -1) {
            if(categoryId === 1){
                newSelectedCategories.splice(0,newSelectedCategories.length);
                newSelectedCategories.push(categoryId);
            }else{
                const indexOfAll = newSelectedCategories.indexOf(1);
                if(indexOfAll !== -1){
                    newSelectedCategories.splice(indexOfAll,1);
                }
                newSelectedCategories.push(categoryId);
            }
        } else {
            newSelectedCategories.splice(indexOfCategory, 1);
            if(newSelectedCategories.length === 0){
                newSelectedCategories.push(1);
            }
        }
        setSelectedCategories(newSelectedCategories);
        if (newSelectedCategories.indexOf(1) !== -1) {
            setFilteredItems(items);
            handleSearch(items);
            return;
        }
        const newFilteredItems = items.filter(item => {
            for (let i = 0; i < item.categories.length; i++) {
                for (let j = 0; j < newSelectedCategories.length; j++) {
                    if (item.categories[i] === newSelectedCategories[j]) {
                        return true;
                    }
                }
            }
            return false;
        });
        setFilteredItems(newFilteredItems);
        handleSearch(newFilteredItems);
    };

    const handleSearch = (currentFilteredItems) => {
        if (query == null || query === "") {
            setItemsToDisplay(currentFilteredItems);
            return;
        }
        const newItemsToDisplay = currentFilteredItems.filter(item => {
            if (item.name.toLowerCase().includes(query.toLowerCase()) || item.description.toLowerCase().includes(query.toLowerCase())) {
                return true;
            }
            return false;
        });
        setItemsToDisplay(newItemsToDisplay);
    }

    const handleQueryChange = (e) => {
        setQuery(e.target.value);
    }

    const handleAddToShoppingCart = (id) => {
        const newSelectedItems = [...selectedItems];
        const indexOfItem = selectedItems.findIndex(item => item.id === id);
        if (indexOfItem !== -1) {
            newSelectedItems[indexOfItem].count++;
        } else {
            const newItem = items.filter(item => item.id === id)[0];
            newItem.count = 1;
            newSelectedItems.push(newItem);
        }
        setSelectedItems(newSelectedItems);
    }

    const handleIncreaseItemCount = (id) => {
        const newSelectedItems = [...selectedItems];
        const indexOfItem = selectedItems.findIndex(item => item.id === id);
        if (indexOfItem !== -1) {
            newSelectedItems[indexOfItem].count++;
        }
        setSelectedItems(newSelectedItems);
    }

    const handleDecreaseItemCount = (id) => {
        const newSelectedItems = [...selectedItems];
        const indexOfItem = selectedItems.findIndex(item => item.id === id);
        if (indexOfItem !== -1) {
            newSelectedItems[indexOfItem].count--;
            if (newSelectedItems[indexOfItem].count === 0) {
                newSelectedItems.splice(indexOfItem, 1);
            }
        }
        setSelectedItems(newSelectedItems);
    }

    const getClass = (id) => {
        let type = flatCategories.find(x => x.id == id).type;
        switch (type) {
            case "all":
                return classes.all;
            case "item":
                return classes.item;
            case "sub-item":
                return classes.subItem;
        }
    }

    const handleOnCatalogueItemClick = (id) => {
        const clickedItem = items.filter(item => item.id === id)[0];
        setItemWithDetails(clickedItem);
        window.location.href = '#details';
    }

    const handleCloseItemDetails = () => {
        setItemWithDetails(null);
    }

    return (
        <div className={classes.layoutContainer}>
            {showLoader &&
            <CircularProgress style={{position: 'absolute', top: '50%', left: '50%'}}/>
            }
            <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                    <Typography variant='h4' style={{paddingTop: 20}}>
                        Katalog
                    </Typography>
                </Grid>

                <Grid item md={5} xs={12}>
                            <span>
                                <TextField
                                    id="search"
                                    label="Pretraga"
                                    style={{margin: 8, minWidth: 300, maxWidth: 300}}
                                    placeholder="npr. Papir"
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={query}
                                    onChange={handleQueryChange}
                                    onKeyDown={e => {
                                        if (e.key === "Enter") handleSearch(filteredItems)
                                    }}
                                />
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.searchButton}
                                startIcon={<Search/>}
                                onClick={() => {
                                    handleSearch(filteredItems)
                                }}
                            >
                                Pretraži artikle
                            </Button>
                            </span>


                </Grid>

                <Grid item md={3} xs={12}>

                </Grid>

                {/*Kategorije*/}
                <Grid item md={2} xs={12} style={{marginTop: 40}}>
                    <Paper>
                        <Accordion
                            expanded={!isMobileLayout}
                            onChange={()=>setIsMobileLayout(!isMobileLayout)}>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant='h6'>Kategorije</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant='h6'>
                                    <List
                                        className={classes.list}
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        {
                                            flatCategories.map(category => {
                                                const labelId = `checkbox-list-label-${category.id}`;

                                                return (
                                                    <div>
                                                        <Divider/>
                                                        <ListItem
                                                            key={category.id}
                                                            role={undefined}
                                                            dense button
                                                            onClick={handleCheckboxToggle(category.id)}
                                                            className={getClass(category.id)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={selectedCategories.indexOf(category.id) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{'aria-labelledby': labelId}}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId} primary={category.name}/>
                                                        </ListItem>
                                                    </div>
                                                );
                                            })
                                        }
                                    </List>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                </Grid>

                <Grid item md={7} style={{marginTop: 40}}>
                    <Grid container spacing={6} id="details">
                        {itemWithDetails !== null &&
                        <CatalogueItemDetails item={itemWithDetails} handleClose={handleCloseItemDetails}
                                              handleAddToShoppingCart={handleAddToShoppingCart}/>

                        }

                        {itemsToDisplay.slice((selectedPage-1)*pageSize,selectedPage*pageSize).map(item => <CatalogueItem item={item}
                                                                   handleAddToShoppingCart={handleAddToShoppingCart}
                                                                   handleClick={handleOnCatalogueItemClick}/>)}
                    </Grid>
                </Grid>

                <Grid item md={3} xs={12} style={{marginTop: 40}}>
                    <ShoppingCart selectedItems={selectedItems} total={total} increaseCount={handleIncreaseItemCount}
                                  decreaseCount={handleDecreaseItemCount}/>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12}
                      className={classes.pagination}>
                    <Pagination color="primary" count={numberOfPages} page={selectedPage} onChange={(e,page)=>{setSelectedPage(page)}}/>
                </Grid>
            </Grid>
        </div>
    );
}

export default Catalogue;