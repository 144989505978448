import React, {useEffect, useState} from "react";
import {
    Button, Divider,
    Grid,
    makeStyles,
    Paper, Snackbar, TextField, Typography,
} from "@material-ui/core";
import ShoppingCartItem from "./ShoppingCartItem";
import {Alert} from "@material-ui/lab";
import CustomerEmailHtml from '../static/customer-email.txt';


const useStyles = makeStyles((theme) => ({
    shoppingCartContainer: {
        padding: 10,
    },
    shoppingCartList: {
        width: '100%',
        height: '100%'
    },
    customerEmail: {
        marginTop: 30,
        width: '100%'
    }
}));

function ShoppingCart(props) {

    const classes = useStyles();
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const [isCorrectEmail, setIsCorrectEmail] = useState(true);
    const [customerEmail, setCustomerEmail] = useState("")
    const [helperText, setHelperText] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleCustomerEmailChange = (e) => {
        setCustomerEmail(e.target.value);
        setIsCorrectEmail(true);
        setHelperText("");
    }

    const handleSendOrder = () => {
        if (!emailRegex.test(customerEmail)) {
            setIsCorrectEmail(false);
            setHelperText("Format email adrese nije tačan, format treba izgledati kao: hamza.heco@gmail.com");
            return;
        }
        if (props.selectedItems.length === 0) {
            setIsCorrectEmail(false);
            setHelperText("Morate odabrati artikle da bi pokrenuli proces narudžbe.");
            return;
        }


        const headers = {
            'accept': 'application/json',
            'api-key': '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',
            'content-type': 'application/json',
        };

        let customerMessage = `
                    <html>
        
                    <head>
                        <link rel=\"preconnect\" href=\"https://fonts.gstatic.com\">
                        <link href=\"https://fonts.googleapis.com/css2?family=Roboto&display=swap\" rel=\"stylesheet\">
                        <style>
                            .tab {
                                width: 600px;
                                text-align: center;
                                border: 2px solid black;
                            }
                            th, td {
                                border: 2px solid black;
                                border-collapse: collapse;
                            }
                            .full-width {
                                width: 100%;
                            }
                    
                            .row {
                                display: flex;
                            }
                    
                            ;
                    
                            .col {
                                display: flex;
                            }
                    
                            .center {
                                justify-content: center;
                            }
                    
                            .space-evenly {
                                justify-content: space-evenly;
                            }
                    
                            .roboto {
                                font-family: 'Roboto', sans-serif;
                            }
                            
                        </style>
                    </head>
                    <body>
                        <div class=\"col full-width\">
                            <div class=\"row\">
                                <div class=\"col\">
                                    <div class=\"row center\">
                                        <h1 class=\"roboto\">Vaša narudžba je zaprimljena, uskoro ćete dobiti predračun na email. Hvala Vam na vašem vremenu!</h1>
                                    </div>
                                    <div class=\"row center\"> 
                                        <table class=\"tab\">
                                            <tr>
                                                <td>Id</td>
                                                <td>Naziv</td>
                                                <td>Količina</td>
                                                <td>Jedinična cijena (KM)</td>
                                                <td>Ukupno (KM)</td>
                                            </tr>
                                            ${props.selectedItems.map(item => `
                                            <tr>
                                                <td>${item.id}</td>
                                                <td>${item.name}</td>
                                                <td>${item.count}</td>
                                                <td>${item.price}</td>
                                                <td>${(item.price * item.count).toFixed(2)}</td>
                                            </tr>
`
        )}
                                        </table>
                                    </div>
                                    <div class=\"row center\">
                                        <h2>Total: ${props.total.toFixed(2)} KM</h2>
                                    </div>
                                    <div class=\"row center\">
                                        <img src="https://drive.google.com/file/d/13oEqBswtE15EpdA4mRbnwRk1ycEuRY9K/view?usp=sharing" width="300"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </body>
                    </html>
                `;


        let data = {
            sender: {
                name: "Office Store d.o.o. Sarajevo",
                email: "officestoresarajevo@gmail.com"
            },
            to: [
                {
                    name: "Kupac",
                    email: customerEmail
                }
            ],
            subject: "Potvrda narudžbe",
            htmlContent: customerMessage
        }

        fetch("https://api.sendinblue.com/v3/smtp/email", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                setShowSuccess(true);
            })
            .catch((error) => {
                console.error('Error:', error);
                setShowError(true);
            });

        let orderDetails = `
                    <html>
        
                    <head>
                        <link rel=\"preconnect\" href=\"https://fonts.gstatic.com\">
                        <link href=\"https://fonts.googleapis.com/css2?family=Roboto&display=swap\" rel=\"stylesheet\">
                        <style>
                            .tab {
                                width: 600px;
                                text-align: center;
                                border: 2px solid black;
                            }
                            th, td {
                                border: 2px solid black;
                                border-collapse: collapse;
                            }
                            .full-width {
                                width: 100%;
                            }
                    
                            .row {
                                display: flex;
                            }
                    
                            ;
                    
                            .col {
                                display: flex;
                            }
                    
                            .center {
                                justify-content: center;
                            }
                    
                            .space-evenly {
                                justify-content: space-evenly;
                            }
                    
                            .roboto {
                                font-family: 'Roboto', sans-serif;
                            }
                            
                        </style>
                    </head>
                    <body>
                        <div class=\"col full-width\">
                            <div class=\"row\">
                                <div class=\"col\">
                                    <div class=\"row center\">
                                        <h1 class=\"roboto\">Nova narudžba sa email adrese: ${customerEmail}</h1>
                                    </div>
                                    <div class=\"row center\"> 
                                        <table class=\"tab\">
                                            <tr>
                                                <td>Id</td>
                                                <td>Naziv</td>
                                                <td>Količina</td>
                                                <td>Jedinična cijena (KM)</td>
                                                <td>Ukupno (KM)</td>
                                            </tr>
                                            ${props.selectedItems.map(item => `
                                            <tr>
                                                <td>${item.id}</td>
                                                <td>${item.name}</td>
                                                <td>${item.count}</td>
                                                <td>${item.price}</td>
                                                <td>${(item.price * item.count).toFixed(2)}</td>
                                            </tr>`
        )}
                                        </table>
                                    </div>
                                    <div class=\"row center\">
                                        <h2>Total: ${props.total.toFixed(2)} KM</h2>
                                    </div>
                                    <div class=\"row center\">
                                        <img src="https://drive.google.com/file/d/13oEqBswtE15EpdA4mRbnwRk1ycEuRY9K/view?usp=sharing" width="300"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </body>
                    </html>
                `;

        data = {
            sender: {
                name: "Office Store d.o.o. Sarajevo",
                email: "officestoresarajevo@gmail.com"
            },
            to: [
                {
                    name: "Narudžba",
                    email: "officestoresarajevo@gmail.com"
                }
            ],
            subject: "Potvrda narudžbe",
            htmlContent: orderDetails
        }
        fetch("https://api.sendinblue.com/v3/smtp/email", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                setShowSuccess(true);
            })
            .catch((error) => {
                console.error('Error:', error);
                setShowError(true);
            });


    }

    const handleClose = (code) => {
        switch (code) {
            case 's':
                setShowSuccess(false);
                break;
            case 'e':
                setShowError(false);
                break;
        }
    }

    useEffect(() => {
        setIsCorrectEmail(true);
        setHelperText("");
    }, [props.selectedItems])

    return (
        <Paper className={classes.shoppingCartContainer}>

            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
                className={classes.shoppingCartList}
            >
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Korpa
                    </Typography>
                </Grid>

                {props.selectedItems.map(item => <ShoppingCartItem item={item} increaseCount={props.increaseCount}
                                                                   decreaseCount={props.decreaseCount}/>)}
            </Grid>
            <Grid item xs={12}>
                <Divider/>
                {/*<Typography variant="h6" style={{marginTop: 20}}>*/}
                {/*    <b>Total: {props.total.toFixed(2)} KM</b>*/}
                {/*</Typography>*/}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    className={classes.customerEmail}
                    error={!isCorrectEmail}
                    id="customer-email"
                    label="Vaša email adresa"
                    placeholder="nas.kupac@firma.com"
                    value={customerEmail}
                    helperText={helperText}
                    onChange={handleCustomerEmailChange}
                    variant="outlined"
                    size="medium"
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{width: '100%', marginTop: 20}}
                    onClick={handleSendOrder}
                >
                    Pokreni Narudžbu
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Snackbar anchorOrigin={{ horizontal:'center', vertical:'top' }} open={showSuccess} autoHideDuration={10000} onClose={() => handleClose('s')}>
                    <Alert onClose={() => handleClose('s')} severity="success" style={{fontSize: 20}}>
                        Narudžba je uspješno napravljena, predračun stiže na Vaš email u što kraćem roku.
                    </Alert>
                </Snackbar>
                <Snackbar anchorOrigin={{ horizontal:'center', vertical:'top' }} open={showError} autoHideDuration={10000} onClose={() => handleClose('e')}>
                    <Alert onClose={() => handleClose('e')} severity="error" style={{fontSize: 20}}>
                        Desila se greška prilikom slanja narudžbe, molimo pokušajte ponovo. U slučaju da se greška
                        nastavi ponavljati, kontaktirajte nas putem telefona ili nam pošaljite email. Vaš Office Store.
                    </Alert>
                </Snackbar>
            </Grid>
        </Paper>
    );
}

export default ShoppingCart;