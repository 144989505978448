import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Button, Icon} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';
import WhiteLogo from '../static/logo-white-svg.svg';
import RedLogo from '../static/red-logo.jpg';
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        marginTop: 10,
        fontSize: 24
    },
    logoButton: {
        display: 'flex',
        paddingRight: 16,
        paddingTop: 5,
        width: 120,
        height: 60
    }
}));

function LogoButton() {
    const classes = useStyles();
    return (
        <Button component={Link} to='/' style={{ backgroundColor: 'transparent' }}>
            <div className={classes.logoButton}>
                <img src={RedLogo} />
            </div>
        </Button>
    );
}

export default LogoButton;