import CssBaseline from '@material-ui/core/CssBaseline';
import React, {useEffect} from 'react';
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import {theme} from './theme';
import './App.css';
import { Grid, ThemeProvider} from '@material-ui/core';
import Home from './home/Home'
import Navbar from './nav/Navbar';
import Footer from './footer/Footer';
import Catalogue from "./catalogue/Catalogue";
import UploadCatalogue from "./catalogue/UploadCatalogue";

function App() {

    useEffect(()=>{
        fetch("https://office-store-backend.herokuapp.com/")
            .then(res=>res.text())
            .then(text=>console.log(text))
            .catch(err=>console.log(err));
    },[]);

    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={theme}>
                <Router>
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start">
                        <Navbar/>
                        <Switch>
                            <Route path='/upload'>
                                <UploadCatalogue/>
                            </Route>
                            <Route path='/catalogue'>
                                <Catalogue/>
                            </Route>
                            <Route path='/'>
                                <Home/>
                            </Route>
                        </Switch>
                        <Footer/>
                    </Grid>
                </Router>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App;
