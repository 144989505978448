import React from "react";
import {
    Button,
    Grid,
    makeStyles, Paper, Typography,
} from "@material-ui/core";
import Carousel from "./Carousel";


const useStyles = makeStyles((theme) => ({
    outerContainer: {
        padding: 20
    },
    text: {
        marginTop: 30
    }
}));

function CatalogueItemDetails(props) {

    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Paper className={classes.outerContainer}>
                <Carousel images={props.item.images} handleClose={props.handleClose}/>
                <Typography variant="h2" className={classes.text}>
                    {props.item.name}
                </Typography>
                {/*<Typography variant="h4" className={classes.text}>*/}
                {/*    {props.item.price.toFixed(2)} KM*/}
                {/*</Typography>*/}
                <Typography variant="body1" className={classes.text}>
                    {props.item.description}
                </Typography>
                <Button color="primary" variant="contained" style={{fontSize: 18, width:'100%', marginTop:40}} onClick={() => props.handleAddToShoppingCart(props.item.id)}>
                    Dodaj u korpu
                </Button>
            </Paper>
        </Grid>
    );
}

export default CatalogueItemDetails;