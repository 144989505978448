import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import LogoButton from './LogoButton'
import { Button } from '@material-ui/core';
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        marginTop: 10
    },
    navbarItem:{
        marginLeft: 10,
        textTransform: 'capitalize',
        color: 'white',
        width: 160,
        padding: 20,
        fontSize: 22
    }
}));


function Navbar() {
    const classes = useStyles();

    return (
        <AppBar position="static">
            <Toolbar className={classes.toolbar}>
                <LogoButton />
                <Button size='large' className={classes.navbarItem} component={Link} to='/'>Početna</Button>
                <Button size='large' className={classes.navbarItem} component={Link} to='/catalogue'>Katalog</Button>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
