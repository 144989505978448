import { createMuiTheme } from '@material-ui/core/styles';
import { red, cyan, deepOrange } from '@material-ui/core/colors'

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#ef4f4f"
        },
        secondary: {
            main: "#74c7b8"
        },
    },
    typography: {
        fontSize: 12
    }
});