import React from "react";
import {Add, Remove} from '@material-ui/icons';
import {
    Button, Divider,
    Grid, IconButton,
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    shoppingCartItem: {
        width: 500,
        minHeight: 140
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    media: {
        width: '15%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    image: {
        display: 'block',
        width: '90%',
        marginLeft: '10%'
    },
    text: {
        width: '65%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 180
    },
    actions: {
        width: '20%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    counterInput: {
        width: 60
    },
    counterButton: {
        height: 40,
        width: 40
    },
    counterRow: {
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: '100%'
    }
}));

function ShoppingCartItem(props) {

    const classes = useStyles();


    return (
        <Grid item md={12} xs={12} className={classes.shoppingCartItem}>
            <div className={classes.row}>
                <div className={classes.media}>
                    <img src={props.item.images[0]} className={classes.image}/>
                </div>
                <div className={classes.text}>
                    <Typography variant="h6">
                        {props.item.name}
                    </Typography>
                    <Divider style={{marginTop:20}}/>
                    {/*<Typography variant="body1" color="textSecondary">*/}
                    {/*    Jed. cijena: {props.item.price} KM*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="body1" color="textSecondary" style={{marginTop:10}}>*/}
                    {/*    <b>Ukupno: {(props.item.price * props.item.count).toFixed(2)} KM</b>*/}
                    {/*</Typography>*/}
                </div>
                <div className={classes.actions}>
                    <div className={classes.counterRow}>
                        <TextField
                            className={classes.counterInput}
                            size="small"
                            value={props.item.count}
                            variant="outlined"/>
                    </div>
                    <div className={classes.row}>
                        <IconButton
                            color="primary"
                            className={classes.counterButton}
                            onClick={()=>props.decreaseCount(props.item.id)}
                        >
                            <Remove/>
                        </IconButton>
                        <IconButton
                            color="secondary"
                            className={classes.counterButton}
                            onClick={()=>props.increaseCount(props.item.id)}
                        >
                            <Add/>
                        </IconButton>
                    </div>
                </div>
            </div>
        </Grid>
    );
}

export default ShoppingCartItem;