import React, {useState} from "react";
import {Button, makeStyles, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    input: {
        display: 'none',
    },
    row: {
        width: '95vw',
        height: 80,
        display: 'flex',
        justifyContent: 'center'
    }
}));

function UploadCatalogue(props) {
    const classes = useStyles();

    const [selectedCatalogue, setSelectedCatalogue] = useState(null);
    const [isCatalogueSelected, setIsCatalogueSelected] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState(null);
    const [isCategoriesSelected, setIsCategoriesSelected] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleChangeCatalogue = (e) => {
        const newSelectedCatalogue = e.target.files[0];
        setSelectedCatalogue(newSelectedCatalogue);
        setIsCatalogueSelected(true);
        e.target.value = null;

        const formData = new FormData();
        formData.append('catalogue', selectedCatalogue);
        fetch("https://office-store-backend.herokuapp.com/catalogue", {
            method: "POST",
            body: formData
        })
            .then(res => res.json())
            .then(json => {
                console.log(json);
                setShowSuccess(true);
            })
            .catch(err=>{
                console.log(err);
                setShowError(true);
            })
    }

    const handleChangeCategories = (e) => {
        const newSelectedCategories = e.target.files[0];
        setSelectedCategories(newSelectedCategories);
        setIsCategoriesSelected(true);
        e.target.value = null;

        const formData = new FormData();
        formData.append('categories', newSelectedCategories);
        fetch("https://office-store-backend.herokuapp.com/categories", {
            method: "POST",
            body: formData
        })
            .then(res => res.json())
            .then(json => {
                console.log(json);
                setShowSuccess(true);
            })
            .catch(err=>{
                console.log(err);
                setShowError(true);
            })
    }

    const handleSubmission = () => {
    }

    const handleClose = (code) => {
        switch (code) {
            case 's':
                setShowSuccess(false);
                break;
            case 'e':
                setShowError(false);
                break;
        }
    }

    return (
        <div className={classes.container}>

            <div className={classes.row}>
                <input
                    accept="text/*"
                    className={classes.input}
                    id="upload-catalogue"
                    type="file"
                    onChange={handleChangeCatalogue}
                />
                <label htmlFor="upload-catalogue">
                    <Button variant="contained" color="primary" component="span" size="large"
                            onClick={handleSubmission}>
                        Odaberi Katalog
                    </Button>
                </label>
            </div>
            <div className={classes.row}>
                <input
                    accept="text/*"
                    className={classes.input}
                    id="upload-categories"
                    type="file"
                    onChange={handleChangeCategories}
                />
                <label htmlFor="upload-categories">
                    <Button variant="contained" color="primary" component="span" size="large"
                            onClick={handleSubmission}>
                        Odaberi Kategorije
                    </Button>
                </label>
            </div>
            <Snackbar anchorOrigin={{ horizontal:'center', vertical:'top' }} open={showSuccess} autoHideDuration={10000} onClose={() => handleClose('s')}>
                <Alert onClose={() => handleClose('s')} severity="success" style={{fontSize: 20}}>
                    Datoteka je uspješno uploadovana!
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ horizontal:'center', vertical:'top' }} open={showError} autoHideDuration={10000} onClose={() => handleClose('e')}>
                <Alert onClose={() => handleClose('e')} severity="error" style={{fontSize: 20}}>
                    Desila se greška prilikom uploada datoteke, molimo pokušajte ponovno za minutu.
                </Alert>
            </Snackbar>
        </div>
    );
}

export default UploadCatalogue;