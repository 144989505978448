import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HomeBackground from '../static/home-bg.jpg';
import { Button, Typography } from '@material-ui/core';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  homepageLayout: {
    width: '100%',
    height: '90vh',
    backgroundImage: `url(${HomeBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right top',
    display: 'flex',
    justifyContent: 'center',
  },
  textContainer: {
    color: 'white',
    textShadow: '2px 2px black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    marginBottom: 40,
    marginLeft:10,
    animation: "$fadeIn .8s ease-in-out"
  },
  subtitle: {
    marginLeft:10,
    animation: "$fadeIn 1s ease-in-out"
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateX(200px)"
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)"
    }
  },
  catalogueButton: {
    width: 300,
    marginTop: 40,
    height: 60,
    fontSize: 16
  },
  row: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

function Home() {
  const classes = useStyles();
  return (
    <div className={classes.homepageLayout}>
      <div className={classes.textContainer}>
        <div className={classes.row}>
          <Typography variant="h3" className={classes.title}>
            Sve za vašu kancelariju na jednom mjestu
        </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="h5" className={classes.subtitle}>
            Pretražite naš katalog i napravite narudžbu u nekoliko klikova
        </Typography>
        </div>
        <div className={classes.row}>
          <Button variant='contained' color="primary" className={classes.catalogueButton} component={Link} to='/catalogue'>
            Idi na katalog
          </Button>
        </div>

      </div>
    </div>
  );
}

export default Home;
